import React from 'react'

import {
  Container,
  TitleContainer,
  DescriptionContainer,
  LeftAmoeba,
  RightAmoeba,
} from './style'

const Header = () => (
  <Container>
    <TitleContainer>
      <LeftAmoeba />
      <span>Entendendo Melhor</span>
      <h1>
        O que é <br />
        Moebius
      </h1>
    </TitleContainer>
    <DescriptionContainer>
      <RightAmoeba />
      <p>
        A Síndrome de Moebius é considerada rara, pois existem poucos casos no
        mundo. Sabemos que ao receber o diagnóstico muitas dúvidas surgem e,
        pela raridade, pouca informação que vai fazer a diferença no seu dia a
        dia é encontrada.
        <br />
        <br />
        <span>
          Queremos que você saiba que a descoberta da síndrome não é uma
          sentença.
        </span>
      </p>
    </DescriptionContainer>
  </Container>
)

export default Header
