import React from 'react'
import { withTheme } from 'styled-components'

import {
  Container,
  Title,
  CardsContainer,
  Card,
  BottomContainer,
  Description,
  SyndromeFactors,
  Vector1,
  Vector2,
  Rare,
} from './style'

const Statistics = ({ theme }) => (
  <Container>
    <Title>Números sobre a síndrome:</Title>
    <CardsContainer>
      <Card color={theme.colors.tertiaryDarkest}>
        <p>
          Média de <br />
          <span>1</span>
          <br />
          casos a cada
          <br />
          <span>50 mil</span>
          <br />
          crianças
        </p>
      </Card>
      <Card color={theme.colors.primaryDarkest}>
        <p>
          No mundo existem
          <br />
          relatos de
          <br />
          <span>5 mil</span>
          <br />
          casos
        </p>
      </Card>
      <Card color={theme.colors.secondaryDarkest}>
        <p>
          Brasil tem
          <br />
          registrado <br />
          <span>600</span>
          <br />
          casos
        </p>
      </Card>
      <Card color={theme.colors.quaternaryDarkest}>
        <p>
          EUA tem
          <br />
          registro de
          <br />
          <span>1000</span>
          <br />
          casos
        </p>
      </Card>
    </CardsContainer>
    <BottomContainer>
      <Description>
        <Vector1 />
        <h2>Por que uma pessoa nasce com a síndrome?</h2>
        <p>
          A Síndrome de Moebius é considerada <Rare>rara</Rare>, por isso não
          existe conhecimento suficiente para determinar uma causa específica.
          Existem algumas possibilidades que foram levantadas a partir de
          estudos sobre a síndrome. Porém, ainda
          <span> não existem estudos suficientes que possam comprovar </span>
          esses fatores como causas determinantes da síndrome.
        </p>
      </Description>
      <SyndromeFactors>
        <p>Fatores genéticos;</p>
        <p>
          Déficit na irrigação sanguínea do tronco cerebral entre a 6ª e 8ª
          semana da gestação;
        </p>
        <p>
          Uso de medicamentos, vacinas, álcool, tabaco e cocaína durante a
          gravidez;
        </p>
        <p>Doenças como diabetes, epilepsia e traumatismo (quedas);</p>
        <p>
          Uso de Misoprostol (Cytotec) - medicamento indicado no tratamento de
          gastrite e úlcera, também usada com abortivo.
        </p>
        <Vector2 />
      </SyndromeFactors>
    </BottomContainer>
  </Container>
)

export default withTheme(Statistics)
