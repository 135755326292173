import styled from 'styled-components'
import Amoeba1 from 'assets/vectors/understand-moebius-particulars-vector1.svg'
import Amoeba2 from 'assets/vectors/understand-moebius-particulars-vector2.svg'
import Amoeba3 from 'assets/vectors/understand-moebius-particulars-vector3.svg'
import Amoeba4 from 'assets/vectors/understand-moebius-particulars-vector4.svg'
import Amoeba5 from 'assets/vectors/understand-moebius-particulars-vector5.svg'
import Amoeba6 from 'assets/vectors/understand-moebius-particulars-vector6.svg'
import Amoeba7 from 'assets/vectors/understand-moebius-particulars-vector7.svg'
import Amoeba8 from 'assets/vectors/understand-moebius-particulars-vector8.svg'
import Amoeba9 from 'assets/vectors/understand-moebius-particulars-vector9.svg'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  background: ${({ theme }) => theme.colors.backgroundMedium};
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      padding: 80px 28px;
    }
  `}
`

export const Card = styled.div`
  width: 1028px;
  height: 1513px;
  display: flex;
  padding-left: 100px;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.primaryMedium};
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      height: fit-content;
      padding: 28px;
    }
  `}
`

export const Title = styled.h2`
  max-width: 805px;
  color: ${({ theme }) => theme.colors.backgroundLight};
  margin-top: 85px;
  margin-bottom: 60px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      max-width: 222px;
      ${theme.fonts.heading4};
      margin-top: 0px;
      margin-bottom: 30px;
    }
  `}
`

export const Description = styled.p`
  max-width: 664px;
  ${({ theme }) => theme.fonts.mediumText};
  color: ${({ theme }) => theme.colors.backgroundLight};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      max-width: 100%;
      ${theme.fonts.smallTextBlack};
    }
  `}
`

export const MainItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 100px;
`

export const Row = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      flex-direction: column;
    }
  `}
`

export const MainItem = styled.div`
  position: relative;
  width: 266px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: ${({ marginRight }) => marginRight};

  p {
    ${({ theme }) => theme.fonts.heading4};
    color: ${({ theme }) => theme.colors.backgroundLight};
    margin-top: ${({ marginTop }) => marginTop};
    z-index: 2;
  }
  span {
    ${({ theme }) => theme.fonts.smallTextBlack};
    ${({ translatex }) => translatex && `transform: translateX(91px);`};
    color: ${({ theme }) => theme.colors.backgroundLight};
    margin-top: 16px;
    z-index: 2;
  }

  ${({ theme, marginMobile }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 162px;
      height: 150px;
      margin: 0px;
      ${marginMobile};
      
      p {
        width: 123px;
        ${theme.fonts.mediumTextBold};
      }
      span {
        ${theme.fonts.smallTextBlack};
        transform: none;
        width: 123px;
      }
    }
    @media (max-width: ${theme.breakPoints.smallMobile}) {
      margin-top: 40px;
    }
  `}
`

export const ItemIcon1 = styled(Amoeba1)`
  position: absolute;
  left: -36px;
  top: -27px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 128px;
      height: 128px;
      left: -36px;
    }
  `}
`

export const ItemIcon2 = styled(Amoeba2)`
  position: absolute;
  top: -25px;
  left: 36px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 143px;
      height: 137px;
      top: -18px;
      left: -15px;
    }
  `}
`

export const ItemIcon3 = styled(Amoeba3)`
  position: absolute;
  left: 32px;
  top: -14px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 127px;
      height: 144px;
      left: -17px;
      top: -6px;
    }
  `}
`

export const ItemIcon4 = styled(Amoeba4)`
  position: absolute;
  top: -23px;
  left: 24px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 132px;
      height: 137px;
      left: -15px;
    }
  `}
`

export const ItemIcon5 = styled(Amoeba5)`
  position: absolute;
  top: -4px;
  left: 52px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 135px;
      height: 137px;
      left: -17px;
    }
  `}
`

export const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 124px;
  transform: translateX(-100px);

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      margin-top: 33px;
      transform: none;
      flex-direction: column;
    }
  `}
`

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ItemsTitle = styled.p`
  max-width: 352px;
  margin-right: 30px;
  ${({ theme }) => theme.fonts.heading3};
  text-align: end;
  color: ${({ theme }) => theme.colors.backgroundLight};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      ${theme.fonts.mediumTextBold};
      margin-bottom: 74px;
    }
  `}
`

export const Item = styled.div`
  position: relative;
  max-width: 300px;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      margin-bottom: 74px;
    }
  `}
`

export const ItemDescription = styled.p`
  position: relative;
  padding-left: 26px;
  ${({ theme }) => theme.fonts.smallTextBlack};
  color: ${({ theme }) => theme.colors.backgroundLight};
  z-index: 2;
`

export const ItemIcon6 = styled(Amoeba6)`
  position: absolute;
  top: -14px;
  left: 0px;
  z-index: 1;
`

export const ItemIcon7 = styled(Amoeba7)`
  position: absolute;
  top: -23px;
  left: 0px;
`

export const ItemIcon8 = styled(Amoeba8)`
  position: absolute;
  top: -11px;
  left: 0px;
`

export const MithContainer = styled.div`
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 133px;
  width: 100%;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: fit-content;
      margin-top: 82px;
      margin-bottom: 40px;
  `}
`

export const MithTitle = styled.div`
  position: relative;
  p {
    position: relative;

    font-family: 'Montserrat-Black';
    font-size: 250px;
    line-height: 305px;
    color: transparent;
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: ${({ theme }) => theme.colors.primaryDarkest};
    z-index: 2;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      p {
        font-size: 100px;
        line-height: 91px;
        -webkit-text-stroke-width: 2px;
      }
    }
  `}
`
export const MithDescription = styled.div`
  max-width: 589px;
  p {
    ${({ theme }) => theme.fonts.largeText};
    text-align: center;
    color: ${({ theme }) => theme.colors.neutralDarkest};
  }
  span {
    ${({ theme }) => theme.fonts.largeBold};
    color: ${({ theme }) => theme.colors.neutralDarkest};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      max-width: 100%;

      p {
        ${theme.fonts.smallTextBlack};
      }
      span {
        ${theme.fonts.mediumTextExtraBold};
      }
    }
  `}
`

export const Rain = styled(Amoeba9)`
  position: absolute;
  top: -163px;
  left: -148px;
  z-index: 1;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 131px;
      height: 161px;
      top: -89px;
      left: -44px;
    }
  `}
`
