import React from 'react'

import {
  Container,
  Card,
  Title,
  Description,
  MainItemsContainer,
  Row,
  MainItem,
  ItemIcon1,
  ItemIcon2,
  ItemIcon3,
  ItemIcon4,
  ItemIcon5,
  BottomContainer,
  ItemsContainer,
  ItemsTitle,
  Item,
  ItemDescription,
  ItemIcon6,
  ItemIcon7,
  ItemIcon8,
  MithContainer,
  MithTitle,
  MithDescription,
  Rain,
} from './style'

const Particulars = () => (
  <Container>
    <Card>
      <Title>Quais são as características de quem tem a síndrome?</Title>
      <Description>
        A principal característica de quem possui a síndrome é a paralisia
        facial periférica, lateral ou bilateral, envolvendo os pares craneanos
        VI e VII (nervo facial e abducente). Essa paralisia significa que o
        Moebita não terá movimentos faciais e oculares naturalmente e as
        sequências práticas são:
      </Description>
      <MainItemsContainer>
        <Row>
          <MainItem marginRight="10px" marginMobile="margin-right: 0px">
            <p>
              Dificuldade de <br />
              mover os olhos
            </p>
            <ItemIcon1 />
          </MainItem>
          <MainItem
            marginRight="60px"
            marginTop="45px"
            marginMobile="margin-left: auto;margin-right:0px;"
            translatex
          >
            <p>
              Ausência <br />
              de lágrima
            </p>
            <span>
              o que pode provocar problemas de <br />
              lubrificação dos olhos
            </span>
            <ItemIcon2 />
          </MainItem>
          <MainItem marginRight="60px">
            <p>
              Dificuldades <br />
              de fala
            </p>
            <ItemIcon3 />
          </MainItem>
        </Row>
        <Row>
          <MainItem
            marginRight="90px"
            marginTop="45px"
            marginMobile="margin-left: auto;margin-right:0px;"
          >
            <p>
              Dificuldade
              <br />
              para sugar
            </p>
            <span>
              leite na amamentação,
              <br />
              mastigar alimentos e<br />
              deglutir
            </span>
            <ItemIcon4 />
          </MainItem>
          <MainItem marginRight="60px">
            <p>
              Ausência
              <br />
              do sorriso
            </p>
            <ItemIcon5 />
          </MainItem>
        </Row>
      </MainItemsContainer>
      <BottomContainer>
        <ItemsTitle>
          Outras características que podem ocorrer na Síndrome de Moebius:
        </ItemsTitle>
        <ItemsContainer>
          <Item marginBottom="55px">
            <ItemIcon6 />
            <ItemDescription>
              Muitos Moebitas apresentam alguma deformação ou atrofia nos pés ou
              mãos
            </ItemDescription>
          </Item>
          <Item marginBottom="49px">
            <ItemIcon7 />
            <ItemDescription>Pode ocorrer estrabismo;</ItemDescription>
          </Item>
          <Item>
            <ItemIcon8 />
            <ItemDescription>
              Atrofia da musculatura da língua e fenda no palato.
            </ItemDescription>
          </Item>
        </ItemsContainer>
      </BottomContainer>
    </Card>
    <MithContainer>
      <MithTitle>
        <Rain />
        <p>mito:</p>
      </MithTitle>
      <MithDescription>
        <p>
          Os moebitas <span>raramente possuem deficiência intelectual.</span>{' '}
          Muitas vezes as alterações de expressão ou deformidades físicas podem
          causar distúrbios emocionais que podem ser confundidos com uma
          deficiência intelectual, o que é raro em pessoas com a síndrome.
        </p>
      </MithDescription>
    </MithContainer>
  </Container>
)

export default Particulars
