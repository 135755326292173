import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {
  Container,
  Smile,
  Quote,
  Name,
  Stripe,
  AuthorContainer,
  AuthorText,
  AuthorDate,
  AuthorName,
  AuthorDescription,
  Amoeba,
} from './style'

const MoebiusCarrier = () => (
  <Container>
    <Quote>
      <Smile />
      <p>
        Os Moebitas, nome que chamamos os portadores de Moebius, são pessoas
        incríveis que com os estímulos certos terão muitas oportunidades e um
        caminho cheio de superação.
      </p>
    </Quote>
    <Name>
      <span>
        mo <br />
        ebi <br />
        tas <br />
      </span>
    </Name>
    <Stripe>
      <AuthorContainer>
        <StaticImage
          src="../../../assets/images/paul-jubilus-moebius.png"
          alt=""
          placeholder="blurred"
          className="picture"
        />
        <AuthorText>
          <Amoeba />
          <AuthorDate>(1853-1907)</AuthorDate>
          <AuthorName>Paul Julius Möbius</AuthorName>
          <AuthorDescription>
            o primeiro a descrever os sintomas, relatou 44 casos
          </AuthorDescription>
        </AuthorText>
      </AuthorContainer>
    </Stripe>
  </Container>
)

export default MoebiusCarrier
