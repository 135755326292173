import styled from 'styled-components'
import Vector from 'assets/vectors/understand-moebius-carrier-smile.svg'
import Vector1 from 'assets/vectors/understand-moebius-carrier-vector1.svg'

export const Container = styled.div`
  width: 100%;
  height: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.colors.backgroundMedium};
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      height: 891px;
    }
    @media (max-width: ${theme.breakPoints.smallMobile}) {
      height: 950px;
    }
  `}
`

export const Smile = styled(Vector)`
  position: absolute;
  left: -460px;
  top: -83px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      height: 153px;
      width: 193px;
      left: unset;
      right: 0px;
      top: -164px;
    }
    @media (max-width: ${theme.breakPoints.smallMobile}) {
      right: -40px;
    }
    @media (max-width: ${theme.breakPoints.smallestMobile}) {
      right: -60px;
    }
  `}
`

export const Quote = styled.h3`
  position: relative;
  max-width: 454px;
  margin-top: 200px;

  p {
    position: relative;
    ${({ theme }) => theme.fonts.heading4};
    text-align: center;
    color: ${({ theme }) => theme.colors.tertiaryDarkest};
    z-index: 1;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      max-width: 100%;
      padding: 0px 28px;
      margin-top: 240px;

      p {
        ${theme.fonts.mediumTextBold};
      }
    }
  `}
`

export const Name = styled.div`
  position: absolute;
  right: 30px;
  span {
    font-family: 'Montserrat-Black';
    font-size: 250px;
    line-height: 225px;
    color: ${({ theme }) => theme.colors.backgroundLight};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      left: 10px;
      right: unset;
      span {
        font-size: 100px;
        line-height: 91px;
      }
    }
  `}
`

export const Stripe = styled.div`
  width: 100%;
  height: 232px;
  margin-top: 369px;
  background: ${({ theme }) => theme.colors.quaternaryDarkest};
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      margin-top: 100px;
      height: 339px;
  `}
`

export const AuthorContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 500px;

  .picture {
    transform: translateY(-28px);

    ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 178px;
      height: 178px;
    }
  `}
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      margin-right: 0px;
      flex-direction: column;
      align-items: center;
  `}
`

export const Amoeba = styled(Vector1)`
  position: absolute;
  left: -447px;
  bottom: 56px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 129px;
      height: 145px;
      top: -202px;
      left: -21px;
      bottom: unset;
    }
  `}
`

export const AuthorText = styled.div`
  position: relative;
  display: flex;
  max-width: 300px;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 22px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      margin-top: 0px;
      margin-left: 0px;
      align-items: center;
    }
  `}
`

export const AuthorDate = styled.p`
  ${({ theme }) => theme.fonts.mediumTextBold};
  color: ${({ theme }) => theme.colors.neutralDarkest};
  margin-bottom: 12px;
`

export const AuthorName = styled.p`
  ${({ theme }) => theme.fonts.heading4};
  color: ${({ theme }) => theme.colors.backgroundLight};
  margin-bottom: 8px;
`

export const AuthorDescription = styled.p`
  ${({ theme }) => theme.fonts.mediumText};
  color: ${({ theme }) => theme.colors.backgroundLight};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      text-align: center;
    }
  `}
`
