import React from 'react'
import { ThemeProvider } from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import theme from 'styles/theme'

import Seo from 'components/Seo'
import Layout from 'components/Layout'
import Header from 'components/UnderstandMoebius/Header'
import MoebiusCarrier from 'components/UnderstandMoebius/MoebiusCarrier'
import Particulars from 'components/UnderstandMoebius/Particulars'
import Statistics from 'components/UnderstandMoebius/Statistics'

const UnderstandMoebius = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakPoints.biggestMobile})`,
  })
  return (
    <ThemeProvider theme={theme}>
      <Layout css={{ backgroundColor: theme.colors.tertiaryDarkest }}>
        <Seo
          title="O que é
          Moebius"
          description="A Síndrome de Moebius é considerada rara, pois existem poucos casos no mundo. Sabemos que ao receber o diagnóstico muitas dúvidas surgem e, pela raridade, pouca informação que vai fazer a diferença no seu dia a dia é encontrada.Queremos que você saiba que a descoberta da síndrome não é uma sentença."
        />
        <Header />
        <MoebiusCarrier />
        <Particulars isMobile={isMobile} />
        <Statistics />
      </Layout>
    </ThemeProvider>
  )
}

export default UnderstandMoebius
