import styled from 'styled-components'
import Amoeba1 from 'assets/vectors/understand-moebius-statistics-vector1.svg'
import Amoeba2 from 'assets/vectors/understand-moebius-statistics-vector2.svg'

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding-top: 100px;
  padding-bottom: 60px;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.colors.backgroundLight};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      padding-top: 70px;
    }
  `}
`

export const Title = styled.h2`
  ${({ theme }) => theme.fonts.heading3};
  margin-bottom: 60px;
  color: ${({ theme }) => theme.colors.primaryDarkest};
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.heading4};
      width: 200px;
      text-align: center;
    }
  `}
`

export const CardsContainer = styled.div`
  display: flex;
  width: 1200px;
  flex-wrap: wrap;
  justify-content: center;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 900px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      flex-direction: column;
      padding: 0 28px;
    }
  `}
`

export const Card = styled.div`
  height: 342px;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ color }) => color};

  p {
    ${({ theme }) => theme.fonts.heading4};
    text-align: center;
    color: ${({ theme }) => theme.colors.backgroundLight};
  }

  span {
    ${({ theme }) => theme.fonts.heading2};
    color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: ${({ theme }) => theme.colors.backgroundLight};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 450px;
    }
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      width: 400px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
    }
  `}
`

export const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      margin-top: 150px;
  `}
`

export const Description = styled.div`
  width: 615px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: ${({ theme }) => theme.colors.primaryDarkest};
    z-index: 2;
  }

  p {
    ${({ theme }) => theme.fonts.largeText};
    color: ${({ theme }) => theme.colors.neutralDarkest};
    margin-top: 50px;
  }

  span {
    ${({ theme }) => theme.fonts.heading4};
    color: ${({ theme }) => theme.colors.primaryDarkest};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      padding: 0px 28px;

      h2 {
        width: 228px;
        padding-left: 60px
        ${theme.fonts.heading4};
      }
      p {
        ${theme.fonts.smallTextBlack};
      }
      span {
        ${theme.fonts.mediumTextExtraBold};
      }
  `}
`

export const Rare = styled.span`
  ${({ theme }) => theme.fonts.largeBold};
  color: ${({ theme }) => theme.colors.neutralDarkest} !important;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.mediumTextExtraBold} !important;
    }
  `}
`

export const Vector1 = styled(Amoeba1)`
  position: absolute;
  top: -220px;
  left: -320px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 200px;
      height: 200px;
      left: -30px;
      top: -117px;
    }
  `}
`

export const Vector2 = styled(Amoeba2)`
  position: absolute;
  bottom: 0px;
  right: -320px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 200px;
      height: 200px;
      right: 20px;
    }
  `}
`

export const SyndromeFactors = styled.div`
  position: relative;
  padding-bottom: 100px;
  margin-top: 115px;
  transform: translateX(200px);

  p {
    position: relative;

    width: 589px;
    ${({ theme }) => theme.fonts.largeBold};
    color: ${({ theme }) => theme.colors.neutralDarkest};
    text-align: end;
    margin-top: 35px;
    z-index: 2;
  }
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      transform: none;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      margin-top: 80px;
      padding-left: 28px; 
      padding-right: 28px; 
      p {
        ${theme.fonts.mediumTextExtraBold};
        text-align: start;
        width: 100%;
      }
    }
  `}
`
